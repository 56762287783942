* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  :root {
    --primaryColor: #fff;
    --mainWhite: #fff;
    --offWhite: #f7f7f7;
    --mainBlack: #222;
    --mainGrey: #ececec;
    --darkGrey: #cfcfcf;
    --mainTransition: all 0.3s linear;
    --mainSpacing: 3px;
    --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
    --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  }
  /* globals */
  body {
    padding-top: 66px;
    color: var(--mainBlack);
    background: var(--mainWhite);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    line-height: 1.4;
  }
  h1 {
    font-size: 3em;
    line-height: 1;
    margin-bottom: 0.5em;
  }
  h2 {
    font-size: 2em;
    margin-bottom: 0.75em;
  }
  h3 {
    font-size: 1.5em;
    line-height: 1;
    margin-bottom: 1em;
  }
  h4 {
    font-size: 1.2em;
    line-height: 1.25;
    margin-bottom: 1.25em;
    text-align: center;
  }
  h5 {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 1.5em;
  }
  h6 {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 1.5em;
  }
  
  .btn-primary {
    display: inline-block;
    text-decoration: none;
    letter-spacing: var(--mainSpacing);
    color: var(--mainBlack);
    background: var(--primaryColor);
    padding: 0.4rem 0.9rem;
    border: 3px solid var(--primaryColor);
    transition: var(--mainTransition);
    text-transform: uppercase;
    cursor: pointer;
  }
  .btn-primary:hover {
    background: transparent;
    color: var(--primaryColor);
  }
  .loading {
    text-transform: capitalize;
    text-align: center;
    margin-top: 3rem;
  }
  .error {
    text-align: center;
    text-transform: uppercase;
    margin: 2rem 0;
  }
  .empty-search {
    text-align: center;
    text-transform: capitalize;
    margin: 2rem 0;
    padding: 1rem;
    letter-spacing: var(--mainSpacing);
  }
  /* end of globals */
  /* Navbar */
  .cursiveDiv{
    text-align: left;
    
  }

  .cursiveTop{
    font-size: 1.5rem;
    font-weight: normal;
    text-align: right;  
  }
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: -0.25rem 1rem;
    background: var(--mainWhite);
    z-index: 1;
  }
  .nav-header {
    display: flex;
    justify-content: space-between;
  }
  .nav-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding-right: 1rem;
  }
  .nav-icon {
    font-size: 1.5rem;
    color:  #010D37;
  }
  .text-matching-icon
  {
    font-size: 1rem;
    color:  #010D37;
  }
  .centralized-div
  {
    margin-top: 10rem;
  }
  centralized-div-for-book {
    margin-top: .05rem; }
  
  .scrollingDiv{
    height: 25rem;
    overflow-y: scroll;
  }
  centralized-div-for-book{
    margin-top: .05rem;
  }
  .nav-links {
    height: 0;
    overflow-y: hidden; 
    transition: var(--mainTransition);
    list-style-type: none;
    background-color: #010D37;
    margin-top: 0rem;
  }
  @media screen and (min-width: 300px) {
    .nav-links {
      overflow-y: scroll; 
    }
    .centralized-div
    {
      margin-top: 12rem;
    }
  }
  @media screen and (min-width: 992px) {
    .nav-links {
      overflow-y: hidden; 
    }
    .centralized-div
    {
      margin-top: 15rem;
    }
  }

  .nav-links :-webkit-scrollbar {
    display: none; }
  
    .borderedLinks
    {
      list-style-position:inside;
      border: 1px solid white;
    }
  
 

  .nav-links a {
    display: block;
    text-decoration: none;
    padding: 1rem 0;
    color: var(--mainWhite);
    transition: var(--mainTransition);
    text-align: center;
    font-size: .8rem;
    font-weight: 50;
    letter-spacing: var(--mainSpacing);
  }
  .nav-links a:hover {
    color: var(--primaryColor);
  }
  
  .show-nav {
    height: 335px;
  }
  .div-for-nav
{
 height: 2.5rem;
}


  @media screen and (min-width: 768px) {
    .div-for-nav
{
 height: 9.5rem;
}
    .nav-btn {
      display: none;
    }
    .nav-center {
      max-width: 1170px;
      margin: 0 auto;
      display: flex;
    }
    .nav-links {
      height: auto;
      display: flex;
      margin-left: 4rem;
      margin-top: 1.5rem;
    }
    .nav-links a {
      padding: 0.5rem 0;
    }
  }
  /* end of navbar */
  /* Hero */
  .defaultHero,
  .roomsHero,
  .joinusHero {
    min-height: calc(100vh - 517px);
   
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 5s, height 5s, transform 5s; 
  }
    
  .roomsHero {
    background-image: url("../../images/gamingLogo.png");
    min-height: calc(100vh - 66px);
  }
  .joinusHero {
    min-height: calc(100vh - 66px);
    background-image: url("../../images/gamingLogo.png");
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .noimageHero {
    min-height:40vh;
    background-color: #51971A;
    display: flex;
    align-items: center;
    justify-content: center; }
  /* End of Hero */
  /* Banner */
  .banner {
    display: inline-block;
    /* background: rgba(0, 0, 0, 0.5); */
    color: var(--mainBlack);
    padding: 2rem 1rem;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
  }
  .banner h1 {
    font-size: 2.5rem;
  }
  .banner div {
    width: 10rem;
    height: 0px;
    background: #010D37;
    margin: 1.7rem auto;
  }
  .banner p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 576px) {
    .banner {
      padding: 2rem 3rem;
    }
    .banner h1 {
      font-size: 3rem;
    }
  }
  @media screen and (min-width: 992px) {
    .banner {
      padding: 2rem 6rem;
    }
    .banner h1 {
      font-size: 2rem;
    }
  }
  /* End of Banner */
  /* Title */
  .section-title {
    text-align: center;
    margin-bottom: 4rem;
  }
  .section-title h4 {
    font-size: 2rem;
    letter-spacing: var(--mainSpacing);
    text-transform: capitalize;
    margin-bottom: 1rem;
  }
  .section-title div {
    width: 5rem;
    height: 5px;
    margin: 0 auto;
    background: var(--primaryColor);
  }
  /* end of Title */
  
  /* services */
  .services {
    padding: 5rem 0;
  }
  .services {
    background: #fff;
    text-align: center;
  }
  .services-center {
    width: 75vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 50px;
  }
  .services-center-secondLine{

    width: 92vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 50px;
  }
  .service span {
    display: inline-block;
    /* color: var(--primaryColor); */
    background-image: url("../../images/iconImage1.png");
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
  .services h6 {
    letter-spacing: var(--mainSpacing);
    text-transform: capitalize;
  }
  .services p {
    width: 80%;
    margin: 0 auto;
  }
  @media screen and (min-width: 992px) {
    .services-center {
      width: 95vw;
      max-width: 1170px;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .services p {
      width: 100%;
    }
  }
  /*end of services */
  /* featured rooms */
  
  .featured-rooms {
    padding: 5rem 0;
  }
  .featured-rooms-center {
    width: 80vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 40px;
  }
  @media screen and (min-width: 776px) {
    .featured-rooms-center {
      width: 90vw;
      grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    }
  }
  @media screen and (min-width: 992px) {
    .featured-rooms-center {
      width: 95vw;
      max-width: 1170px;
    }
  }
  /* end pf featured rooms */
  /* room */
  .room {
    box-shadow: var(--lightShadow);
    transition: var(--mainTransition);
  }
  .room:hover {
    box-shadow: var(--darkShadow);
  }
  
  .img-container {
    position: relative;
  }
  .img-container img {
    width: 100%;
    display: block;
    transition: var(--mainTransition);
  }
  .price-top {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    color: var(--mainWhite);
    padding: 0.3rem 0.6rem 0.5rem;
    border-bottom-right-radius: 1rem;
    font-size: 0.5rem;
    text-align: center;
    transition: var(--mainTransition);
  }
  .price-top h6 {
    margin-bottom: 0;
    font-size: 0.9rem;
    font-weight: 300;
    letter-spacing: var(--mainSpacing);
  }
  .room-link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(0);
    transition: all 0.3s linear;
  }
  .img-container:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  .img-container:hover img {
    opacity: 0.3;
  }
  .img-container:hover .price-top {
    opacity: 0;
  }
  .img-container:hover .room-link {
    transform: translate(-50%, -50%) scale(1);
  }
  .room-info {
    background: var(--darkGrey);
    text-transform: capitalize;
    padding: 0.5rem 0;
    text-align: center;
    font-weight: 700;
    letter-spacing: var(--mainSpacing);
  }
  /* end of room  */
  /* single room*/
  
  .single-room {
    padding: 5rem 0 0 0;
  }
  .single-room-images {
    width: 80vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 50px;
  }
  .single-room-images img {
    width: 100%;
    display: block;
  }
  .single-room-info {
    width: 80vw;
    display: grid;
    grid-template-columns: 1fr;
    margin: 2rem auto;
  }
  .desc,
  .info {
    margin: 1rem 0;
  }
  .desc h3 {
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
  }
  .desc p {
    line-height: 1.5;
  }
  .info h3,
  .info h6 {
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
  }
  
  .info h6 {
    font-weight: 300;
  }
  .room-extras {
    width: 80vw;
    margin: 0 auto 3rem auto;
  }
  .room-extras h6 {
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
  }
  .extras {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    grid-column-gap: 2rem;
    grid-row-gap: 1rem;
  }
  @media screen and (min-width: 992px) {
    .single-room-images,
    .single-room-info,
    .room-extras {
      width: 95vw;
      max-width: 1170px;
    }
    .single-room-info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
    }
    .info {
      padding-left: 3rem;
    }
  }
  /* end of single room*/
  /* roomlist */
  .roomslist {
    padding: 5rem 0;
  }
  .roomslist-center {
    width: 80vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 30px;
  }
  
  @media screen and (min-width: 776px) {
    .roomslist-center {
      width: 90vw;
    }
  }
  @media screen and (min-width: 992px) {
    .roomslist-center {
      width: 95vw;
      max-width: 1170px;
    }
  }
  /* end of roomlist */
  /*  rooms fitler*/
  .filter-container {
    padding: 5rem 0;
  }
  .filter-form {
    width: 60vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 40px;
  }
  .form-group {
    background: #ffffff;
  }
  .form-group label {
    display: block;
    letter-spacing: var(--mainSpacing);
    margin-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
  .form-control {
    width: 100%;
    background: transparent;
    font-size: 1rem;
  }
  .size-inputs {
    display: flex;
  }
  .size-input {
    width: 100%;
    padding: 0.2rem;
    border: 2px solid #51971A;
    border-radius: 0.3rem;
    margin-right: 0.3rem;
  }
  .single-extra label {
    display: inline-block;
    font-size: 0.8rem;
    margin-left: 0.5rem;
  }
  @media screen and (min-width: 776px) {
    .filter-form {
      width: 70vw;
    }
  }
  @media screen and (min-width: 992px) {
    .filter-form {
      width: 95vw;
      max-width: 1170px;
    }
  }
  /* end of rooms fitler*/
  .spaceDiv{
    height: 80px;
 }
 .spaceDivLess {
  height: 50px; }
  
  .joinUsDiv {
    align-items: center; 
    }
  .joinUsActualDiv{
      align-items: center;
    }

  .serviceBookingDiv {
      align-items: center; }  

  .sideTextBoxes
  {
    display: block;
    float: left;
    margin-left: .5rem;
   
  }  
  .positionOfRates
  {
    margin-top: .5rem;
  }
  .positionOfTerms
  {
    margin-top: .5rem;
  }
  .positionOfSubmit
  {
      display: block;
      float: left;
      margin-left: 4.5rem;
      margin-top: 45rem;
     
  }
  @media screen and (min-width: 300px) {
    .positionOfSubmit {
      display: block;
      float: left;
      margin-left: 8.5rem;
      margin-top: 0rem;
   } 
    }
  
  @media screen and (min-width: 992px) {
    .positionOfSubmit {
      display: block;
      float: left;
      margin-left: -11.5rem;
      margin-top: 90rem;
      
     } 
    }

  
    @media screen and (min-width: 576px) {
      .joinUsDiv {
        margin-left: 5rem;
      }
      .serviceBookingDiv
      {
      margin-left: 5rem;
      }
      .joinUsActualDiv
      {
      margin-left: 5rem;
      }
  
    }
    @media screen and (min-width: 992px) {
      .joinUsDiv {
        margin-left: 25rem;
      }
      .serviceBookingDiv
      {
      margin-left: 10rem;
      }
      .joinUsActualDiv
      {
       margin-left: 28rem;
      }
  
    }
  /* blinking text start */
.blink{
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  padding: 15px; 
  text-align: center;
  line-height: normal; 
  /*margin-top: 6rem; */
  display: inline-block;
  color: black;
  padding: 2rem 1rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
  font-weight: bolder;
}
.labelText {
  font-size: .85rem;
  font-weight: 100;
  letter-spacing: var(--mainSpacing);
  color: black;
}

@keyframes blink{
0%{opacity: 0;}
50%{opacity: .5;}
100%{opacity: 1;}
}
/* blinking text End */
.futureExpansion{
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  padding: 15px;
  text-align: center;
  line-height: normal; 
}
.faqText {
  color: #000;
  font-size: small;
  align-items: flex-start;
  background-color: #FFF; 
  padding-left: 10px;
  padding-right: 10px;

}
  .faqTextCentre {
    color: #000;
    font-size: small;
    align-items: center;
    background-color: #FFF; }
    @media screen and (min-width: 576px) {
      .faqTextCentre {
        margin-left: 5rem; } }
    
    @media screen and (min-width: 992px) {
      .faqTextCentre {
        margin-left: 45rem; } }  
.blueBreak{
  background-color: #010D37;
  height: 5px;
}
.blueBreakThin{
  background-color: #010D37;
  height: 2.5px;
  }
.whiteBreakThin{
    background-color: #fff;
    height: 5px; 
  }  
  .greenBreakThin{
    background-color: #51971A;
    height: 5px;
  }
.blueButton
{
height: 24px !important;
width: 80px;
background-color: #010D37;
color: #fff;
border: 2px solid #51971A;
border-radius: 0.3rem;
}
.blueButtonFooter {
  height: 38px !important;
  background-color: #010D37;
  color: #fff;
  border: 2px solid #51971A;
  border-radius: 0.3rem;
  padding-right: .5rem;
  padding-left: .5rem;
 }
button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}


.iconImage1 {
  background-image: url("../../images/iconImage1.png" );
  height: 300;
  width: 150; 
  background-repeat: no-repeat}

/* Slider CSS Start */
.textInBlack{
  color: #000;
}
.textInCentre {
  text-align: center; 
  
}
@media screen and (min-width: 576px) {
  .textInCentre {
    text-align: center; 
    padding-top: 70px; 
     } 
  
  }

@media screen and (min-width: 992px) {
  .textInCentre {
    text-align: center; 
   padding-top: 85rem;
  } 
}
.displayNone{
  display: none;
}
.displayBlock
{
  display: block;
}
* {box-sizing: border-box;}
body {font-family: Verdana, sans-serif;}
.mySlides {display: block;}
img {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
  max-width: 100%;
  position: relative;
  margin: auto;
}
.ssImageWidth
{
  width: 100.5%;
}
.rentalCharges {
  padding-left: 15rem !important; }
@media screen and (max-width: 576px) {
  .ssImageWidth {
    padding-top: 5rem !important; }
  .rentalCharges {
      padding-left: 2rem !important; }
    
  }

@media screen and (max-width: 992px) {
  .ssImageWidth {
    padding-top: 0rem; }
  }
  
  

.ssImageWidthForRate
{
  width: 90%;
}
.ssImageWidthForSteps{
  width: 60%;
} 

@media screen and (max-width: 576px) {
  .ssImageWidthForRate {
    padding-top: 1rem !important; } 
  .ssImageWidthForSteps{
    padding-top: 1rem !important; 
    }    
  }

@media screen and (max-width: 992px) {
  .ssImageWidthForRate {
    padding-top: 0rem; }
  .ssImageWidthForSteps{
    padding-top: 0rem; 
    } 
  }
/* Caption text */
.text {
  color: #000;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 80px;
  width: 100%;
  text-align: center; }
 

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.topImpTextRental {
  color: #010D37;
  float: center;
  text-align: center !important;
  margin-top: 16rem;
  margin-left: .85rem;
  font-size: 1.7rem;
  font-weight: bolder; }

.topImpText{
  color: red;
  margin-top: 0rem;
  margin-left: .85rem;
  font-size: 1.2rem;
  font-weight: bolder;
}
.div-for-trademark{
  float:left; 
 } 
 .div-for-trademark-text{
  float:right; 
  width:115rem;
 } 
.footerPriceText{
  color:#51971A;
  margin-top: 3rem;
  margin-left: .85rem;
  font-size: 1.6rem;

}
/* The dots/bullets/indicators */
.dot {
  height: 5px;
  width: 5px;
  margin: 0 2px;
  background-color:transparent;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color:transparent;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4} 
  to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {font-size: 11px;
         padding: 1100px 12px;}
  .topImpTextRental {
          color: #010D37;
          float: center;
          text-align: center !important;
          margin-top: 21rem;
          margin-left: .85rem;
          font-size: 1.7rem;
          font-weight: bolder; }      
}
@media only screen and (min-width: 397px) {
  .text {
    padding: 1046px 12px;
  } }


/* Slider CSS End */
/* Radio button CSS Start*/
/* Radio button CSS Start*/
.radio-size {
  padding-top: 1px; }

  
/* Radio button CSS End*/

/* table CSS Start*/
table {
  text-align: left;
  border: 2px solid #51971A;
  
th, td,tr{
  text-align: left;
  border: 2px solid #010D37;
  padding-left: 4px;
  padding-right: 4px;
}
}

.tableborder{
  width: 100%;
  border: 1px solid #51971A;
}
/* table CSS End*/
  
.rates{
  width: 500;
  height:300;
}
.questions{
  color:#010D37;
  font-weight: bold;
}
.contactUsDivBordered
{
  border: green 3px;
  border-style: outset;
 
}
.contactUsInsetDivBordered{
  border: green 3px;
  border-style: inset;
}
@media screen and (max-width: 300px) {
  .contactUsDivBordered {
    width: 20rem !important; } 
  .contactUsInsetDivBordered {
    width: 20rem !important; } 

  }

@media screen and (min-width: 992px) {
  .contactUsDivBordered {
    width: 35rem; } 
  .contactUsInsetDivBordered {
    width: 35rem !important; } 
  }
  /* Firefox old*/
@-moz-keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
} 

@-webkit-keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
} 
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
      opacity:1;
  }
  50% {
      opacity:0;
  }
  100% {
      opacity:1;
  }
} 
.blink-image {
  -moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 2s infinite ease-in-out; /* IE */
  animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
}

/* for vedios CSS start */
.youtube-video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}
.youtube-video-container +div{
  width: 300px;
  height: 360px;
}
.youtube-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
  /* for vedios CSS End */

   /* for desktop */
   .whatsapp_float {
    position: fixed;
    width: 74px;
    height: 74px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 63px;
    float: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
      width: 64px;
      height: 64px;
      bottom: 30px;
      right: 20px;
      font-size: 54px; 
      float: center;
    }
}
/* Loader  start*/
/* Loader  start*/
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  z-index: 1000;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* loader end*/
/* loader end*/

/* Popup style start */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000 !important; 
}
 
.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - -156px);
  background:#51971A;
  border-radius: 4px;
  padding: 5px;
  border: 1px solid #999;
  overflow: auto;
  z-index: 1000;
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 0px);
  top: calc(100vh - 85vh - -162px);
  background: #FFF;
  width: 39px;
  height: 26px;
  /* border-radius: 50%; */
  line-height: 20px;
  text-align: center;
  border: 1px solid #010D37;
  font-size: 19px; }
/* Popup style end */


/*image spliter start*/
/* Reset. */
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

/* Panels. */
.splitview {
  position: relative;
  width: 100%;
  min-height: 45vw;
  overflow: hidden;
}

.panel {
  position: absolute;
  width: 100vw;
  min-height: 45vw;
  overflow: hidden;
}

  .panel .content {
      position: absolute;
      width: 100vw;
      min-height: 45vw;
      color: #FFF;
  }

  .panel .description {
      width: 25%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
  }

  .panel img {
      box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.15);
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
  }


.bottom {
  background-color: rgb(44, 44, 44);
  z-index: 1;
}

  .bottom .description {
      right: 5%;
  }

.top {
  background-color: rgb(77, 69, 173);
  z-index: 2;
  width: 50vw;

  /*-webkit-clip-path: polygon(60% 0, 100% 0, 100% 100%, 40% 100%);
  clip-path: polygon(60% 0, 100% 0, 100% 100%, 40% 100%);*/
}

  .top .description {
      left: 5%;
  }

/* Handle. */
.handle {
  height: 100%;
  position: absolute;
  display: block;
  background-color: rgb(253, 171, 0);
  width: 5px;
  top: 0;
  left: 50%;
  z-index: 3;
}

/* Skewed. */
.skewed .handle {
  top: 50%;
  transform: rotate(30deg) translateY(-50%);
  height: 200%;
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  transform-origin: top;
}

.skewed .top {
  transform: skew(-30deg);
  margin-left: -1000px;
  width: calc(50vw + 1000px);
}

.skewed .top .content {
  transform: skew(30deg);
  margin-left: 1000px;
}

/* Responsive. */
@media (max-width: 900px) {
  body {
      font-size: 75%;
  }
}
/*image spliter end*/


/* carsouel start */
* {box-sizing:border-box}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: block;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

/* carsouel end */
